import React, { useRef } from "react";
import { getS3PresignedUrl } from "../api/gxGraphql";
import { v4 as uuidv4 } from "uuid";

export default function UploadFileButton({
  onClick,
  children,
  classes,
  className,
  onChange,
  setPatchPhoto,
  accept,
  capture,
  type,
}: any): JSX.Element {
  const takePhotoInput: any = useRef<HTMLInputElement>(null);

  async function handlePhoto(event: any) {
    event.preventDefault();
    let file;
    let newFileName = uuidv4() + ".jpg";
    const isPatchPhoto = type === "PATCH";
    
    takePhotoInput.current?.focus();
    if (takePhotoInput.current !== null) {
      file = takePhotoInput?.current.files[0];
    }

    // Generate the S3 key based on the type
    const s3Key = `${type === "PATCH" ? "patches" : "profilePics"}/${newFileName}`;
    
    try {
      // Get the presigned URL
      const response = await getS3PresignedUrl(s3Key);
      const presignedUrl = response.data.s3_presigned_url;
      
      // Modified fetch request with CORS headers
      const uploadResponse = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
        },
        mode: 'cors',
      });

      if (uploadResponse.ok) {
        const fileUrl = `https://s3.amazonaws.com/www.gxsweatpatch.com/${s3Key}`;
        if (isPatchPhoto) {
          setPatchPhoto(fileUrl);
        } else {
          onChange(fileUrl);
        }
      } else {
        console.warn("Upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    // Handle local preview for patch photos
    if (isPatchPhoto) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onChange(reader.result);
    }
  }

  return (
    <>
      <div
        className={className}
        onClick={(e) => {
          if (takePhotoInput === null || takePhotoInput.current === null)
            return;
          takePhotoInput.current.click();
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {children}
      </div>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        capture={capture}
        ref={takePhotoInput}
        onChange={handlePhoto}
      />
    </>
  );
}
