import { WorkoutProps } from './types'
import { totalLoss } from "../screens/ResultsScreen";
import { allCalculationsAndLevels } from "../common/sweatCalculations";
import honeybadger from '../honeybadger'
const host = process.env["REACT_APP_API_HOST"];
const baseHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const sweatTestFragment = `
`

export function login(email: string, password: string) {
  return fetch(`${host}/api/v1/auth/influencers/sign_in`, {
    method: "POST",
    headers: baseHeaders,
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((response) => ({
    "access-token": response.headers.get("access-token") || "",
    client: response.headers.get("client") || "",
    uid: response.headers.get("uid") || "",
  }));
}

export function graphql(
  authHeaders: any,
  query: any,
  variables?: any,
  url?: string
) {
  const headers = {
    ...baseHeaders,
    ...authHeaders,
  };

  const body = JSON.stringify({
    query,
    variables,
  });

  return fetch(`${host}${url || "/graphql"}`, {
    method: "POST",
    headers,
    body,
  })
    .then((response) => {
      if (!response.body) { throw response }
      return response.text();
    })
    .then(JSON.parse)
    .catch((err) => {
      honeybadger.notify(err);
      console.warn(err);
    });
}

export function publicGraphql(query: any, variables?: any) {
  const authHeader = {
    Authorization: `Bearer nqz4fyf9x9o8wd9790cqjvhmwp7ymayldvxvp7mvl4h7vyvi9kmsti3oaoy3zgfj`,
  };
  return graphql(authHeader, query, variables, "/graphql/public");
}

export function getS3PresignedUrl(key: string) {
  return publicGraphql(`
    query GetS3PresignedUrl($key: String!) {
      s3_presigned_url(key: $key)
    }
  `, { key })
}

export const addWorkout = async ({name, activity, duration, facilityType, gender, age, weight, phone, postalCode, patchPhoto, profilePicture, fluid, sodium, fluidConsumed, email, temperature, humidity, country}: WorkoutProps) => {
  const version = "2"
  let sweatRateParams = {
    activity: activity || "RUNNING",
    duration: duration,
    gender: gender,
    facilityType: facilityType,
    language: "en",
    sweatRate: totalLoss(fluid, duration),
    temperature: temperature,
    version: 2,
    weight: weight,
    localFluid: fluid,
    localSodium: sodium,
    age: parseInt(age)
  };


  const { totalSweatLoss, totalSodiumLoss, totalSweatRate, sweatClassification, sodiumLossDescriptor } = allCalculationsAndLevels(sweatRateParams)

  return publicGraphql(`
    mutation CreateSweatTest($input:CreateSweatTestInput!) {
      CreateSweatTest(input:$input) {
        success
        decode_success
        sweat_test {
          id
          created_at
        }
      }
    }
  `, {
    "input":{
      "activity": activity,
      "age": parseInt(age),
      "duration": `${duration} min`,
      "email": email,
      "facility_type": facilityType.toLowerCase(),
      "local_sweat_measured": parseFloat(fluid)*1000,
      "gender": gender,
      "country_code": country,
      "name": name,
      "patch_photo": patchPhoto,
      "phone": `${phone}`,
      "postal_code": postalCode,
      "profile_picture": profilePicture,
      "local_sodium_measured": sodium,
      "temperature": temperature,
      "humidity": humidity !== "" ? humidity : null,
      "fluid_consumed": fluidConsumed,
      "weight": `${weight} kg`,
      "version": version,
      "calculated_whole_body_fluid_loss": totalSweatLoss,
      "calculated_whole_body_sodium_loss": totalSodiumLoss,
      "calculated_sodium_loss_rate_descriptor": sodiumLossDescriptor,
      "calculated_sweat_loss_rate_descriptor": sweatClassification
    }
  })
}

export function getWorkout(date:string, id:string) {
  return publicGraphql(`
    query {
      sweat_test(id: "${id}") {
          name
          local_sweat_measured(unit: "ml", scalar: true)
          local_sodium_measured
          whole_body_sweat_rate(unit: "ml/hr", scalar: true, round: 5)
          whole_body_sodium_loss_rate(unit: "mg/hr", scalar: true, round: 5)
          sodium_loss_rate_descriptor
          created_at
          activity
          gender
          temp(unit: "tempC", scalar: true, round: 5)
          humidity
          facility_type
          duration(unit: "min", scalar: true)
          weight(unit: "kg", scalar: true)
          calculated_whole_body_fluid_loss
          calculated_whole_body_sodium_loss
          profile_picture 
          patch_photo
      }
    }
  `).then(resp => {
    const sweat_test = resp?.data?.sweat_test
    return {
      ...sweat_test, 
      facilityType: sweat_test?.facility_type?.toUpperCase(), 
      fluid: parseFloat(sweat_test.local_sweat_measured), 
      sodium: parseFloat(sweat_test.local_sodium_measured),
      duration: parseFloat(sweat_test.duration),
      temperature: parseFloat(sweat_test.temp),
      profilePicture: sweat_test.profile_picture
    }
  })
}
